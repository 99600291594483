/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Dialog, Toast } from 'vant';
export default {
  props: {
    item: {
      type: Object,
      default: function _default() {}
    },
    topath: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {};
  },
  watch: {},
  computed: {},
  created: function created() {},
  methods: {
    // 点击订单item 带参跳转订单详情页面
    showDetail: function showDetail(orderId, mealId) {
      this.$router.push({
        path: this.topath,
        query: {
          orderId: orderId,
          mealId: mealId
        }
      });
    },
    //领取订单
    getShare: function getShare(data) {
      var _this = this;

      Dialog.confirm({
        title: '提示',
        confirmButtonColor: '#3399ff',
        message: '确定领取此订单并进行支付吗？'
      }).then(function () {
        _this.$api.Meal.receiveShareOrder({
          subOrderId: data.subOrderId,
          orderId: data.orderId,
          mealId: data.mealId,
          userSerial: _this.$utils.Store.getItem('userSerial'),
          userName: _this.$utils.Store.getItem('userLname'),
          mealName: data.mealName,
          orderPrice: data.orderPrice
        }).then(function (response) {
          _this.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;

          if (+code !== _this.$code.success) {
            _this.$utils.Tools.toastShow(msg);

            _this.finished = true;
          } else {
            _this.$utils.Tools.loadHide();

            var _code = response.code,
                _msg = response.msg;

            if (+_code !== _this.$code.success) {
              _this.$utils.Tools.toastShow(_msg);

              _this.finished = true;
            } else {
              Toast('订单领取成功');

              _this.$router.push({
                path: '/OrderRecord'
              });

              _this.onRefresh();
            }
          }
        }).catch(function () {
          _this.$utils.Tools.httpError();
        });
      }).catch(function () {// on cancel
        // Toast('领取失败，请稍后重试')
      });
    }
  }
};