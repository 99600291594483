/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ShareList from '@/components/ShareList/ShareList';
export default {
  name: 'OrderShare',
  components: {
    ShareList: ShareList
  },
  data: function data() {
    return {
      refreshing: false,
      //是否开启下拉加载
      list: [],
      //分享订单列表
      loading: false,
      finished: false,
      finishedtxt: '',
      //结束的话
      curPage: 1,
      //当前页
      totalPage: 2,
      //总页数
      pageNum: 20,
      //每页条数
      totalRecord: 0,
      // 一共几页
      path: '/OrderShareDetail',
      //跳转页面
      tebVal: ['6'],
      //订单状态
      isMore: false,
      startDate: '',
      endDate: ''
    };
  },
  watch: {
    //是否有可加载数据
    isMore: function isMore(val) {
      if (val == true) {
        this.finishedtxt = '';
      } else {
        this.finishedtxt = '没有更多了';
      }
    }
  },
  created: function created() {
    this.getSysDate();
  },
  methods: {
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          _this.finished = true;
        } else {
          _this.today = data.sysDate.substring(0, 10);
          _this.timestamp = _this.$utils.DateUtils.getTimeStamp(data.sysDate) * 1000;
          _this.year = data.sysDate.substring(0, 4);
          _this.month = data.sysDate.substring(5, 7);
          _this.startDate = _this.$utils.DateUtils.getMonthStart(data.sysDate.substring(0, 7));
          _this.endDate = _this.$utils.DateUtils.getMonthEnd(data.sysDate.substring(0, 7));
          _this.goods = [];

          _this.onRefresh();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    //获取分享订单列表
    getShareList: function getShareList() {
      var _this2 = this;

      this.$api.Meal.getShareList({
        pageIndex: this.curPage,
        pageSize: this.pageNum,
        search: {
          startDate: this.startDate,
          mealState: this.tebVal,
          endDate: this.endDate // userSerial: this.$utils.Store.getItem('userSerial'),

        }
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          //返回错误信息
          _this2.$utils.Tools.toastShow(msg);

          _this2.finished = true;
        } else {
          _this2.totalPage = data.totalPage;
          _this2.totalRecord = data.totalRecord;
          _this2.finished = false; //查询到相关内容（内容不为空）

          if (data.records && data.records.length > 0) {
            //当前为第一页
            if (_this2.curPage == 1) {
              _this2.list = data.records; //总页数为1

              if (_this2.totalPage == 1) {
                _this2.finished = true;
              }
            } else {
              //当前页数大于等于2
              _this2.list = [].concat(_toConsumableArray(_this2.list), _toConsumableArray(data.records));
            } //当前非首页


            if (_this2.totalPage != 1) {
              _this2.curPage++;
            } else {
              _this2.curPage = 1;
              _this2.finished = true;
            }

            _this2.isMore = false;
            _this2.finishedtxt = '没有更多了';
          } else {
            _this2.list = [];
            _this2.loading = false;
            _this2.finished = true;
            _this2.showEmpty = true;
            _this2.isMore = true;
            _this2.finishedtxt = '';
          }

          _this2.updateScroll();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    // 跳转订单详情页面
    showDetail: function showDetail(orderId, mealId) {
      this.$router.push({
        path: '/OrderShareDetail',
        query: {
          orderId: orderId,
          mealId: mealId
        }
      });
    },
    // 加载订单数据
    onLoad: function onLoad() {
      var _this3 = this;

      setTimeout(function () {
        if (_this3.refreshing) {
          _this3.list = []; // this.curPage=1;

          _this3.refreshing = false;
        }

        _this3.curPage <= _this3.totalPage && _this3.getShareList();

        if (_this3.list.length >= _this3.totalRecord) {
          _this3.finished = true;
        }

        _this3.loading = false;
      }, 500);
    },
    // 刷新操作
    onRefresh: function onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.curPage = 0; // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.onLoad();
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  updated: function updated() {}
};